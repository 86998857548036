import React, {Component} from 'react';
import {Link} from 'gatsby';
import './categories.scss';

class Categories extends Component<Props> {

    render() {
        const categories = [
            {path: "/", name: "Populære"},
            {path: "elektronikk", name: "Elektronikk"},
            {path: "hjem", name: "Hjem"},
            {path: "klaer", name: "Klær"},
            {path: "skjonnhet-og-sminke", name: "Skjønnhet og sminke"},
            {path: "diverse", name: "Diverse"},
            {path: "reise", name: "Reise"},
            {path: "sport", name: "Sport"},
            {path: "friluft", name: "Friluft"},
            {path: "barn", name: "Barn"}
        ]

        return (
            <ul className="categories">
                {categories.map((c) => {
                    return (
                        <li><Link activeClassName="active" to={"/" + c.path}>{c.name}</Link></li>
                    )
                })}
            </ul>
        );
    }
}

export default Categories;
