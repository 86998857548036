// @flow
import React from 'react';

import './footer.scss';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="bottom-bar">
        Lagd med <span className="heart">♥</span> i Oslo
      </div>
    </footer>
  );
};

export default Footer;
