// @flow
import React, {Component} from 'react';
import Image from 'gatsby-image';
import {Link} from 'gatsby';
import {motion} from "framer-motion"

import './interview-grid.scss';

type
InterviewGridItemProps = {
    interview: any,
    onClick? : Function,
};

class InterviewGridItem extends Component<InterviewGridItemProps> {


    constructor(props) {
        super(props)
        this.state = {
            hover: false
        }
    }

    toggleHover = () => {
        this.setState({hover: !this.state.hover})
    }

    render() {
        const {interview} = this.props;
        const {hover} = this.state;

        return (
            <div className="column is-one-third-desktop is-half-tablet is-full-mobile is-one-quarter-fullhd">
                <a target="__blank" href={interview.url}>
                    <motion.div className="store-image" whileHover={{y: -10}} onMouseEnter={this.toggleHover}
                                onMouseLeave={this.toggleHover}>
                        <div className="url">{new URL(interview.url).hostname}</div>
                        <div className="overlay">
                            <div className="browser">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            {interview.avatar ? <Image
                                fluid={interview.avatar.childImageSharp.fluid}
                                alt={interview.title}/>
                                : <div className="placeholder">Ingen skjermbilde</div>
                            }
                        </div>
                    </motion.div>
                </a>
                <div className="store-info">
                    <a className="store-link" target="__blank" href={interview.url}>
                        {interview.name}
                    </a>
                    <p>
                        {interview.title}
                    </p>
                </div>
            </div>
        );
    }
}

export default InterviewGridItem;
