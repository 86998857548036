// @flow
import React, { Component } from 'react';
import { Link } from 'gatsby';
import './hero.scss';


class Hero extends Component<Props> {

  render() {
    const categories = [
      {path: "elektronikk", name:"Elekronikk"},
      {path: "hjem", name:"Hjem"},
      {path: "klaer", name:"Klær"},
      {path: "sminke", name:"Sminke"}
    ]

    return (
      <div className="hero">
        <h1
          className="is-size-3"
          style={{
            marginBottom: '.2rem',
            color: '#4a4a4a',
            fontWeight: 400,
          }}
        >
        <Link to="/">Butikkguiden</Link>
        </h1>
        <h2
          className="is-size-4"
          style={{
            marginBottom: '.2rem',
            color: '#4a4a4a',
            fontWeight: 400,
          }}
        >
         En enkel oversikt over norske nettbutikker
        </h2>
      </div>
    );
  }
}

export default Hero;
