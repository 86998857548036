// @flow
import React, { Component } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag'
import Logo from '../../images/logo.png';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './header.scss';

type Props = {
  onClick?: Function,
};

class Header extends Component<Props> {

  render() {

    const options = [
  { value: 'one', label: 'One' },
  { value: 'two', label: 'Two', className: 'myOptionClassName' },
  {
   type: 'group', name: 'group1', items: [
     { value: 'three', label: 'Three', className: 'myOptionClassName' },
     { value: 'four', label: 'Four' }
   ]
  },
  {
   type: 'group', name: 'group2', items: [
     { value: 'five', label: 'Five' },
     { value: 'six', label: 'Six' }
   ]
  }
];

    return (
      <nav
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container header">
          <div className="navbar-brand">
            <h1
              className="is-size-3"
              style={{
                marginBottom: '.2rem',
                color: '#4a4a4a',
                fontWeight: 400,
              }}
            >
            <Link to="/">Butikkguiden</Link>
            </h1>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
